#project-cost-section {
  position: relative;

  .content-container {
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      max-height: 70vh;
      background: linear-gradient(139.2deg, #3a8cd5 -9.27%, #2d62fd 100.23%);
    }

    .tree-view-shape {
      position: absolute;
      left: -5%;
      transform: rotate(-60deg);
    }

    .cost-title {
      padding: 6rem 0;

      .background-text {
        color: rgba(var(--title-background-text-grey-rgb), 0.1);
      }

      .title-content {
        .front-display {
          color: var(--app-white-shade);
        }
      }
    }

    .cost-calc-image {
      position: absolute;
      right: 12%;
      top: 0.5rem;
    }

    .question-list-container {
      position: relative;
      z-index: 1;
      background: var(--app-white-shade);
      max-width: 95%;
      margin: 0 auto;
      margin-bottom: 2rem;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      padding: 2rem;
      text-align: center;

      > h1 {
        text-align: center;
        margin-bottom: 1.5rem;
        font-weight: 700;
        color: var(--black-share-variation-1);
      }

      p {
        max-width: 530px;
        margin: 0 auto;
        margin-bottom: 0;
        font-size: 1.125rem;
        color: var(--description-text-color);
        font-weight: 400;
      }

      .quick-quote {
        background-color: var(--color-blue-variation-light);
        border-radius: 25px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 40px;
        padding: 0.5rem 1.2rem;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 2rem;
        margin-top: 3.125rem;
        font-weight: 600;
        font-size: 0.938rem;
        color: var(--app-black-shade);

        &:focus {
          box-shadow: none;
        }
      }

      .question-device {
        h1 {
          font-weight: 700;
          color: var(--black-share-variation-1);
          text-align: center;
        }
      }

      .project-type {
        > div {
          margin: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          .form-label {
            font-size: 1.5rem;
            font-weight: 400;
            color: var(--disabled-text-color);

            &.checked {
              color: var(--black-share-variation-1);
            }
          }

          .form-check-input {
            border-color: var(--disabled-text-color);
            margin-left: 0.2rem;
          }
        }
      }

      .project-platform-type {
        max-width: fit-content;
        margin: 0 auto;
        min-width: 40%;
        border: 1px solid var(--disabled-text-color);
        border-radius: 10px;

        > div {
          margin: 1rem;
          margin-right: 10%;
        }

        .form-label {
          color: var(--black-share-variation-1);
          margin-left: 1rem;
          font-weight: 500;
          font-size: 1.5rem;
          top: 5px;
          position: relative;
        }

        #both {
          & ~ .form-check-label {
            margin-left: 10px;
            margin-top: 3px;
          }
        }
      }

      .question-platform {
        margin-bottom: 6.25rem;

        h4 {
          color: var(--disabled-text-color);
          max-width: 40%;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 1rem;
          margin-top: 2.5rem;
          font-weight: 400;
        }
      }
    }

    .submit-button {
      min-width: 160px;
      height: 45px;
      background-color: var(--color-blue-variation-light);
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 50px;
      font-weight: 600;
      font-size: 0.938rem;

      &:focus {
        box-shadow: none;
      }
    }
  }

  @media screen and (max-width: 62rem) {
    .content-container {
      .cost-calc-image {
        right: 3%;
        top: 2.2rem;
        width: 200px;
      }

      .question-platform {
        .project-platform-type {
          width: 70%;
          max-width: 70%;
        }
      }
    }
  }

  @media screen and (max-width: 48rem) {
    .content-container {
      .cost-calc-image {
        top: 3.5rem;
        width: 30%;
      }

      .cost-title {
        text-align: left;
        justify-content: flex-start;
        margin-left: 2rem;
      }

      .question-list-container {
        .project-type {
          justify-content: space-between !important;
          margin-top: 1rem;

          & > div {
            margin: 0.5rem;

            .form-label {
              font-size: 1rem;
            }
          }

          .web-type {
            width: 65px;
          }

          .mobile-type {
            width: 30px;
            height: 65px;
          }

          .mobile-web {
            width: 65px;
          }
        }

        .question-platform {
          margin-bottom: 2.5rem;

          h4 {
            margin-top: 1.5rem;
          }

          .form-label {
            font-size: 1rem;
            margin-left: 0.5rem;
          }

          .android-icon,
          .ios-icon {
            width: 45px;
          }

          .project-platform-type {
            width: 92%;
            min-width: unset;
            max-width: 92%;
            justify-content: space-between !important;
            padding: 0 1rem;

            & > div {
              margin: 0.5rem;
            }
          }
        }
      }

      #question-data {
        > div {
          text-align: center;

          &:first-child {
            margin-bottom: 1.5rem;
          }
        }

        &.question {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;

          .question-text {
            font-size: 1.25rem !important;
          }

          .question-option-text {
            width: 70%;
            max-width: 320px;
            min-height: 60px;
            min-width: unset;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.project-quote-modal {
  .title {
    color: var(--app-black-shade);
    font-size: 1.125rem;
    margin-bottom: 2.5rem;
  }

  .form-control {
    padding: 0.813rem 1.25rem;
    border-radius: 10px;
    border: 1px solid var(--border-color-input);
    color: rgba(var(--app-black-shade-rgb), 0.8);
    font-size: 1rem;
    margin-bottom: 1.25rem;

    &:focus {
      box-shadow: none;
    }
  }

  .question-start-duration {
    margin-top: 1.875rem;
    margin-bottom: 3.125rem !important;
    color: var(--color-text-black);
    position: relative;

    legend {
      font-size: 1rem;
      margin-bottom: 1.875rem;
    }

    .form-label {
      font-size: 1rem;
      color: var(--check-text-black);
    }

    .form-check {
      float: left;
      width: 50%;
      margin-bottom: 0.5rem;

      input {
        border-color: var(--checkbox-border-color) !important;
      }
    }
  }

  .phone-input-invalid {
    margin-top: 7px !important;
  }

  .form-agree-input {
    color: var(--model-red-color);
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 2.5rem;

    .invalid-feedback {
      position: relative;
      margin-top: 0 !important;
      font-weight: 600;
    }
  }

  #desc {
    height: 115px;
    resize: none;
  }

  .PhoneInput {
    padding: 0.813rem 1.25rem;
    border: 1px solid var(--border-color-input);
    color: rgba(var(--app-black-shade-rgb), 0.8);
    font-size: 1rem;
    border-radius: 10px;

    .PhoneInputInput {
      border: 0;
    }
  }

  .modal-footer {
    border-top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    > button {
      margin-bottom: 2.5rem;
    }

    .quote-submit {
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: var(--blue-variation-light);
      font-size: 0.938rem;
      font-weight: 500;
      height: 44px;
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 50px;
      color: var(--app-black-shade);
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .cancel-button {
      background-color: transparent;
      border: 0;
      color: var(--app-black-shade);
      font-size: 1.25rem;
    }
  }

  .loading-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(var(--backdrop-color-rgb), 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 48rem) {
    .title {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    .form-control,
    .PhoneInput {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    .PhoneInputInput {
      font-size: 0.875rem;
    }

    .question-start-duration {
      margin-top: 1.5rem;
      margin-bottom: 2rem !important;

      .form-check {
        width: 100%;
      }

      .form-label {
        font-size: 0.875rem;
      }
    }

    .form-check-label {
      font-size: 0.875rem;
    }

    .form-agree-input {
      margin-bottom: 1rem;
    }

    .modal-footer {
      & > button {
        margin-bottom: 1.5rem;
        font-size: 0.875rem;
      }

      & > .cancel-button {
        font-size: 1rem;
      }
    }
  }
}
