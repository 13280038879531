.header-container {
  padding: 0px;
  background-color: var(--app-header-bg-hex);
  position: sticky;
  top: 0px;
  z-index: 5;
  box-shadow: 0px 4px 50px rgba(140, 149, 255, 0.1);

  .logo-container {
    flex: 1;
    text-align: center;

    > .nav-link {
      padding: 0;
    }
  }

  .navbar-nav {
    padding-left: 5px;
    padding-right: 18px;
  }

  > nav {
    padding: 5px 0;
    max-width: var(--app-max-width);
    margin: 0 auto;
  }

  .navbar-brand {
    padding-top: 0px;
  }

  .nav-item:nth-child(2) {
    display: none;
  }

  .nav-item .nav-link {
    color: rgba(var(--nav-link-color-rbg), 0.6);
    position: relative;

    &:hover {
      // font-weight: 500;
      color: rgba(var(--nav-link-color-rbg), 1);
    }

    &.active {
      font-weight: 500;
      color: rgba(var(--nav-link-color-rbg), 1);
    }
  }

  .navbar-collapse {
    flex-grow: initial;
  }

  .menu-toggle-default > path {
    fill: var(--app-black-shade);
  }

  .cost-calculator-button {
    background-color: var(--header-button-color-bg);
    color: var(--header-button-text-color);
    min-height: 40px;
    border-bottom-left-radius: 35px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 24px;
    box-shadow: 0px 4px 4px rgba(255, 80, 80, 0.1);

    &:hover {
      background-color: var(--header-button-color-bg);
    }
  }

  .cost-calculator-button-container {
    background-color: var(--app-white-shade);
    padding: 5px 0px;
    text-align: center;
    position: relative;
    width: 100%;
    margin-bottom: 8px;
  }

  .menu-toggle-container {
    margin-left: 0px;

    .btn {
      background-color: transparent !important;
      box-shadow: none;
    }

    > button {
      padding-left: 0;
      padding-right: 5px;
    }
  }

  .content-container {
    .navbar-brand {
      margin-right: 5px;
    }

    > div {
      flex-wrap: wrap;
    }
  }

  .active-nav-icon {
    position: absolute;
    left: -5px;
    bottom: -5px;
  }

  .labo-soft-icon {
    width: 85px;
    height: 60px;
  }

  .logo-image {
    background-image: url("../../assets/images/labo-logo.svg");
    width: 150px;
    height: 52px;
    background-size: contain;
    display: inline-block;
    background-position: 0px 2px;
    background-repeat: no-repeat;
  }

.labo-products-dropdown {
  .dropdown-menu {
    background: var(--color-light-green);
    border: 1px solid transparent;
    padding: 22px 18px 28px 18px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
  }

  .dropdown-item:hover,  .dropdown-item:focus {
    background: var(--color-light-green);
    color: var(--app-black-shade);
  }

  .dropdown-menu > button {
    padding: 0px;
    font-size: 18px;
    font-weight: 500;
  }

  .product-qr-code-list {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.7;
    margin-top: 16px;
    padding-left: 12px;
  }

  .product-qr-code-list .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .product-qr-code-list > div:last-of-type {
    margin-top: 16px;
  }

  .dropdown-menu > button:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

  @media screen and (min-width: 48rem) {
    .labo-soft-icon {
      width: 115px;
      height: 65px;
    }

    .nav-item:nth-child(2) {
      display: block;
    }
    
    .logo-container {
      flex: 1;
      margin-top: -7px;
    }

    .navbar-nav {
      padding-left: 18px;
      padding-right: 18px;
    }

    .navbar-brand {
      top: 3px;
      position: relative;
    }

    .menu-toggle-container {
      margin-left: 5px;

      > button {
        padding-left: 7px;
        padding-right: 12px;
      }
    }

    .content-container {
      .cost-calculator-button {
        margin-left: 15px;
      }
    }

    .cost-calculator-button-container {
      position: absolute;
      top: 8px;
      right: 8px;
      width: auto;
      margin-bottom: 0px;
    }
  }

  @media screen and (min-width: 62rem) {
    .nav-item .nav-link {
      font-size: 14px;
      font-weight: 400;
      padding-left: 0.875rem;
      padding-right: 0.875rem;
    }

    .active-nav-icon {
      left: 5px;
    }

    .navbar-collapse {
      flex: 1;
      height: 100%;
      justify-content: flex-end;
      min-height: 58px;
    }

    .logo-container {
      margin-left: 8px;
    }

    .active-nav-icon {
      left: 12px;
    }

    .cost-calculator-button-container {
      position: relative;
      top: 0px;
      right: 0px;
    }
  }
}

.headerBtn{
  background: white;
  margin-top: -7px;
  margin-right: -27px;
  margin-left: -15px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.headerBtn > span {
  color: rgba(var(--nav-link-color-rbg), 0.7);
}

.txt{
  color: #7a7272;
}

.btn-secondary:hover{
  color: #fff;
  background-color: #fff; 
  border-color: #fff;
}