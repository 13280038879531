#question-data {
  &.question {
    margin-top: 1.563rem;
    margin-bottom: 3rem;

    .question-text {
      color: var(--disabled-text-color) !important;
      font-weight: 600 !important;
      font-size: 1.625rem !important;
      margin-top: 1.25rem !important;
    }

    .question-option-text {
      min-width: 320px;
      min-height: 70px;
      border: 1px solid var(--app-black-shade);
      border-radius: 10px;
      opacity: 0.7;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      color: var(--option-text-color);
      padding: 0.5rem;
      margin: 1.25rem;

      &.active {
        background-color: var(--blue-selected-color);
        border-color: var(--blue-selected-color);
        color: var(--app-white-shade);
      }
    }

    > div:last-child {
      display: block;
      text-align: left;
      padding-left: 1rem;
    }
  }
}
