.footer-container {
  background-color: var(--blue-shade-variation-dark);
  color: var(--app-white-shade);

  h6 {
    color: rgba(var(--app-white-shade-rgb), 0.7);
  }

  > div {
    padding: 3.75rem 2.625rem 0 2.625rem;
  }

  div > ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 1.5rem;

    > li {
      margin-bottom: 1rem;

      ul {
        margin-top: 1rem;
        list-style-type: none;
        list-style-image: url("../../assets/images/list-style-type.svg");

        li {
          margin-bottom: 1rem;
          padding-left: 0.313rem;

          a {
            text-decoration: none;
            color: var(--app-white-shade);

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      a {
        text-decoration: none;
        color: var(--app-white-shade);

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .row > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    ul,
    h6 {
      min-width: 230px;
    }
  }

  .link {
    margin-top: 0;
    margin-bottom: 1.25rem;
    text-align: center;
    font-size: 1rem;
    opacity: 0.8;

    a {
      font-weight: 400;
    }
  }

  .copyright-content {
    padding: 1.25rem;
    border-top: 1px solid rgba(var(--app-white-shade-rgb), 0.5);
    font-size: 1rem;
    text-align: center;
  }

  a {
    cursor: pointer;
    transition: 0.15s linear all;
    font-weight: 500;

    &:hover {
      opacity: 0.7;
    }

    svg {
      margin-right: 0.313rem;
    }
  }

  @media screen and (min-width: 48rem) {
    .link {
      margin-top: 3rem;
    }

    .row > div {
      display: block;
    }
  }
}

.privacy-content-modal,
.terms-content-modal {
  .modal-header {
    border-bottom: 0;
    padding: 0;
    padding-bottom: 1rem;
  }

  .modal-footer {
    border-top: 0;
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    max-height: 95vh;
    overflow: auto;
  }

  .privacy-cancel-button,
  .terms-cancel-buttons {
    border: 1px solid var(--app-black-shade);
    min-width: 140px;
    min-height: 44px;
    color: var(--app-black-shade);
    background-color: var(--app-white-shade);
    border-radius: 0 22px 22px 35px;
    margin-right: 1.75rem;
    opacity: 0.4;
    transition: 0.15s linear all;

    &:hover {
      box-shadow: none;
      opacity: 0.8;
    }
  }

  .spacing {
    padding: 0.25rem 0;
    display: inline-block;
  }
}
