$text-shadow-value-default: 0px 4px 4px rgba(0, 0, 0, 0.25);

// Banner Component Styles Start

#banner {
  padding-bottom: 5rem;
}

#banner-products.banner-component-container .card-container {
  margin-bottom: 0;
}

.banner-component-container {
  background: var(--banner-background-color);
  color: var(--banner-typo-color-one);
  position: relative;

  > div {
    max-width: var(--app-max-width);
  }

  .header-title {
    h1, h3 {
      font-weight: 600;
      line-height: 36px;
      margin: 2em auto 1em auto;
      max-width: 350px;
      font-size: 24px;
      opacity: 0.8;
      transition: 0.5s linear opacity, 0.85s linear transform;

      &.start-animate-lr {
        transform: translate(0, 0);
        opacity: 1;
      }
    }

    .business {
      position: relative;
      display: inline-block;
      z-index: 0;

      &::after {
        content: "";
        position: absolute;
        width: 62%;
        height: 9px;
        background-color: var(--banner-text-underline-shade);
        bottom: 0.313rem;
        left: -3px;
        z-index: -1;
        animation-name: moveLineBottom;
        animation-duration: 0.8s;
      }
    }

    > * {
      padding: 0 0.75em;
      transform: translate(0px, 0px);
    }
  }

  .banner-content-description {
    font-size: 15px;
    font-weight: normal;
    line-height: 30px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;
    transition: 0.5s linear opacity;
    margin-bottom: 0;

    &.start-animate-lr {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  .start-project-button {
    font-size: 16px;
    line-height: 24px;
    padding: 13px 30px;
    background: var(--app-white-shade);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    border-top-left-radius: 0;
    font-weight: 500;
    color: var(--banner-button-text-color);
    opacity: 0.8;
    transform: translate(-20px, 0);
    transition: 0.5s linear opacity, 0.85s linear transform;

    &.start-animate-lr {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  .dots-group-transform {
    position: absolute;
    left: -78px;
    top: 25px;
    display: none;
  }

  .card-3 {
    position: relative;
  }

  .card-container {
    margin: 2.5rem 0.5rem;
    margin-bottom: 3.25rem;
    padding-left: 0;

    .grid-row-content {
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 30px 20px;
    }

    .card {
      min-height: 280px;
      padding: 1.25rem;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0;
      border-bottom-right-radius: 50px;
    }

    .card-body {
      margin-top: 2.188rem;
      padding-left: 0.125rem;

      &::before {
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        animation-duration: 0.8s;
      }
    }

    .mobile-icon,
    .web-app-icon,
    .transform-card-icon {
      position: absolute;
      right: 15px;
      top: 10px;
    }

    .start-animate {
      .cone-icon {
        animation-name: animateCone;
      }

      .circle-half-icon {
        animation-name: animateCircle;
      }

      .double-arrow-icon {
        animation-name: animateDoubleArrow;
      }
    }

    .cone-icon {
      position: absolute;
      bottom: 0.875rem;
      left: 1.25rem;
      transform: rotate(0deg);
      animation-duration: 0.8s;
    }

    .circle-half-icon {
      position: absolute;
      left: 1rem;
      bottom: 0.875rem;
      transform: rotate(0deg);
      animation-duration: 0.8s;
    }

    .double-arrow-icon {
      left: 7rem;
      position: absolute;
      animation-duration: 0.8s;
    }

    .card-1 {
      &.start-animate {
        .card-body {
          &::before {
            animation-name: moveDotsMobile;
          }

          &::after {
            animation-name: moveDotsMobileSecond;
          }
        }
      }

      .card-body {
        &::before {
          background: rgba(var(--card-variation-yellow-rgb), 0.7);
          top: 2.8rem;
          left: 4.5rem;
        }

        &::after {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: rgba(var(--card-variation-yellow-rgb), 0.4);
          position: absolute;
          top: 2.8rem;
          left: 6.5rem;
          animation-duration: 0.8s;
        }
      }
    }

    .card-3 {
      .card {
        border-bottom-right-radius: 0px;
        border-top-left-radius: 50px;
      }

      &.start-animate {
        .card-body {
          &::before {
            animation-name: moveDotsTransform;
          }

          .card-text {
            &::after {
              animation-name: arrowAnimate;
            }
          }
        }
      }

      .card-body {
        margin-top: 4rem;

        &::before {
          background: rgba(var(--card-variation-sky-blue-rgb), 0.4);
          top: 4.8rem;
          left: 10rem;
        }

        .card-text {
          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-left: 7.5px solid transparent;
            border-right: 7.5px solid transparent;
            border-bottom: 15px solid var(--card-triangle-blue);
            margin-left: 1.125rem;
            margin-top: -0.188rem;
            animation-duration: 0.8s;
            transform: rotate(40deg);
          }
        }
      }
    }

    .card-2 {
      .card-body {
        &::before {
          background: rgba(var(--card-variation-violet-rgb), 0.4);
          top: 2.8rem;
          left: 2.8rem;
        }
      }

      &.start-animate {
        .card-body {
          &::before {
            animation-name: moveDotsWeb;
          }
        }
      }

      .bill-suite-card {
        .card-body {
          &::after {
            background: rgba(var(--card-variation-violet-rgb), 0.4);
            top: 4.8rem;
            left: 2.8rem;
            content: "";
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            animation-duration: 0.8s;
          }
        }
      }
    }

    .card-title {
      color: var(--text-primary-color);
      line-height: 24px;
      font-weight: 600;
      max-width: 158px;
    }

    .card-text {
      color: rgba(var(--text-secondary-color-rgb), 0.87);
      font-size: 0.75rem;
      line-height: 18px;
    }

    .requirements-text {
      z-index: 1;
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        height: 7px;
        width: 80%;
        position: absolute;
        right: 0;
        background: var(--sky-blue-variant);
        border-radius: 10px;
        bottom: 0.063rem;
        z-index: -1;
        animation-duration: 0.8s;
      }
    }

    .start-animate {
      .requirements-text {
        &::after {
          animation-name: moveLineTransform;
        }
      }
    }

    .qr-code-card, .bill-suite-card {
      width: 240px;
      height: 240px;
      min-height: unset;
      cursor: pointer;
    }
  }

  .qr-code-card .qr-code-logo {
    max-width: 148px;
    margin-top: 25px;
  }
  
  .qr-code-card .qr-code-icon {
    position: absolute;
    top: 10px;
    right: 12px;
  }

  .qr-code-card.card .card-body::before {
    top: 2rem;
    left: 2rem;
  }

  .qr-code-card.card .card-body::after {
    top: 2rem;
    left: 4.2rem;
  }

  .bill-icon {
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .bill-suite-icon {
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 15px;
  }

  .bill-suite-card > .card-body {
    padding-right: 0;

    .card-title {
      margin-right: 0;
      max-width: 100%;
      text-align: end;
    }
  }

  .line-connect-image {
    display: none;
  }

  .banner-bg-1 {
    display: none;
    left: 0;
    position: absolute;
  }

  .tree-view-shape {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  .banner-tree-group {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .dots-bottom {
    position: absolute;
    bottom: 5px;
    left: calc(50% - 27.5px);
    display: none;
  }

  .shape-icon {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
  }

  .symbol-group {
    display: none;
  }

  .design-line {
    position: absolute;
    right: 20%;
  }

  .line-custom {
    position: absolute;
    display: none;
  }

  .product-bg {
    display: none;
  }

  @keyframes moveLineBottom {
    from {
      bottom: -0.4rem;
    }
    to {
      bottom: 0.313rem;
    }
  }

  @keyframes moveLineTransform {
    from {
      bottom: -1rem;
    }
    to {
      bottom: 0.063rem;
    }
  }
}

@keyframes moveDotsMobile {
  from {
    left: 2rem;
  }
  to {
    left: 4.5rem;
  }
}

@keyframes moveDotsMobileSecond {
  from {
    left: 2rem;
  }
  to {
    left: 6.5rem;
  }
}

@keyframes moveDotsTransform {
  from {
    top: 0rem;
  }
  to {
    top: 4.8rem;
  }
}

@keyframes moveDotsWeb {
  from {
    left: 0rem;
  }
  to {
    left: 2.8rem;
  }
}

@keyframes animateCone {
  from {
    bottom: 0.875rem;
    left: 3.25rem;
    transform: rotate(-90deg);
  }

  to {
    bottom: 0.875rem;
    left: 1.25rem;
    transform: rotate(0deg);
  }
}

@keyframes animateCircle {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes arrowAnimate {
  from {
    margin-left: 0rem;
    margin-top: 0.875rem;
  }

  to {
    margin-left: 1.125rem;
    margin-top: -0.188rem;
  }
}

@keyframes animateDoubleArrow {
  from {
    left: 5rem;
    margin-top: 0.5rem;
  }

  to {
    left: 7rem;
    margin-top: 0rem;
  }
}

#banner-products {
  height: 100%;

  &.banner-component-container .card-container .grid-row-content {
    grid-template-rows: 1fr 1fr;
  }
}


.next-arrow svg {
  transform: rotate(180deg);
}

.carousel ul.control-dots {
  bottom: 30px;

  > span:first-of-type {
    margin-right: 20px;
    margin-left: 40px;
  }

  > span {
    cursor: pointer;
  }
}

@media screen and (min-width: 48rem) {
  .banner-component-container {
    .header-title {
      h1, h3 {
        padding-top: 1em;
        padding-left: 0;
        margin: 0 auto;
      }

      h3 {
        font-size: 28px;
        line-height: 47px;
        max-width: 592px;
        margin-bottom: 20px;
      }

      h1 {
        font-size: 50px;
        line-height: 75px;
        max-width: 650px;
        margin-bottom: 32px;
      }
    }

    .banner-content-description {
      font-size: 18px;
      line-height: 43px;
      margin-left: auto;
    }

    .header-title .business::after {
      bottom: 0.938rem;
      animation-duration: 0.8s;
    }

    .header-title .start-animate .business::after {
      animation-name: moveLineBottom;
    }

    .card-container {
      margin: 2.5rem 0rem;

      .card {
        margin-left: unset;
        margin-right: unset;
      }

      .card-1 {
        z-index: 1;
      }

      .card-2 {
        position: relative;
      }

      .line-connect-image {
        top: -9.5rem;
        position: absolute;
        height: 56%;
        width: 55%;
        left: -4rem;
        display: block;
      }

      .grid-row-content {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "a b"
          "c b";

        .card-1 {
          grid-area: a;
          justify-self: end;
        }

        .card-2 {
          grid-area: b;
          align-self: center;
        }

        .card-3 {
          grid-area: c;
          justify-self: end;
        }
      }
    }

    .dots-group-transform,
    .tree-view-shape,
    .banner-tree-group {
      display: block;
    }

    .banner-tree-group {
      bottom: unset;
      top: 8%;
    }

    .symbol-group {
      display: block;
      position: absolute;
      top: 20px;
      left: 18%;
      height: 22%;
      width: 60%;
    }

    .dots-bottom {
      display: block;
    }

    @keyframes moveLineBottom {
      from {
        bottom: -0.4rem;
      }
      to {
        bottom: 0.938rem;
      }
    }
  }

  .carousel ul.control-dots {
    bottom: 65px;
  }

  #banner-products {
    .card-container {
      margin-top: 5rem;
    }

    .line-connect-image {
      top: -7rem;
      left: -5rem;
    }
  }
}

@media screen and (min-width: 62rem) {
  #banner {
    padding-bottom: 0;
  }

  .banner-component-container {
    padding-right: 30px;

    > div {
      margin: 0 auto;
    }

    .header-title {
      align-items: center;
      justify-content: center;
      display: flex;

      h1, h3 {
        padding-left: 0.1em;
        padding-top: 0;
        margin-top: 0;
        transform: translate(-20px, 0);
        transition: 0.5s linear opacity, 0.85s linear transform;

        &::before {
          content: "";
          height: 85%;
          width: 0.688rem;
          position: absolute;
          margin-top: 1.063rem;
          margin-left: -1.375rem;
          background: var(--color-yellow-tertiary);
        }
      }

      h3::before {
        margin-top: 11px;
      }

      > div {
        padding-left: 2.75rem;
      }

      > * {
        transform: translate(-20px, 0);
      }
    }

    .card-container .line-connect-image {
      top: -10rem;
      position: absolute;
      height: 70%;
      width: 55%;
      left: -2rem;
      display: block;
    }

    .banner-content-description {
      margin-left: 8px;
      margin-right: 65px;
      transform: translate(-20px, 0);
      transition: 0.5s linear opacity, 0.85s linear transform;
    }

    .start-project-button {
      margin-left: 12px;
    }

    .card-container {
      margin-top: 5.31rem;
      margin-bottom: 11.25rem;

      .card-text {
        line-height: 1.25rem;
      }
    }

    .banner-bg-1 {
      display: block;
    }

    .banner-tree-group {
      bottom: 0;
      top: unset;
    }

    .shape-icon {
      display: block;
    }

    .symbol-group {
      top: 20px;
      left: 15px;
      width: 55%;
    }

    .design-line {
      position: absolute;
      left: 36%;
      bottom: 20%;
    }

    .line-custom {
      display: block;
      right: 20%;
      bottom: 20%;
    }

    .product-bg {
      display: block;
      position: absolute;
      max-width: 650px;
      right: 65px;
      z-index: 1;
      bottom: 50px;
    }
  }
}

@media screen and (min-width: 87.5rem) {
  .banner-component-container {
    .card-container {
      .line-connect-image {
        top: -11rem;
      }
    }
  }

  #banner-products {
    .card-container {
      .line-connect-image {
        top: -8rem;
        left: -2rem;
      }
    }
  }
}

// Banner Component Styles End
// About Us Component Styles Start

.about-us-component {
  .about-us-title {
    margin-top: 2.875rem;
    margin-bottom: 2.5rem;
  }

  .sub-title {
    color: var(--title-text-color-secondary);
    max-width: 455px;
    line-height: 1.5em;
    margin: 0 auto 2.5rem auto;
    transform: translate(0, 30px);
    opacity: 0;
    transition: 0.5s ease all, 1.5s ease transform;

    &.start-animate-ud {
      opacity: 1;
      transform: translate(0, 0);
    }

    .ideas {
      font-weight: 700;
      z-index: 1;
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        height: 8px;
        background: var(--light-green-variation);
        bottom: 3px;
        left: 2px;
        width: 54%;
      }
    }
  }

  .about-us-desc {
    max-width: 720px;
    font-size: 0.875rem;
    color: var(--description-text-color);
    margin-bottom: 3rem;
    line-height: 2.2em;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    margin: 0 auto 3rem auto;
    transform: translate(0, 30px);
    opacity: 0;
    transition: 1s ease opacity, 2s ease transform;

    &.start-animate-ud {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .about-us-list {
    list-style-type: none;
    margin-top: 3.75rem;
    padding: 0 1rem;

    .list-content {
      text-align: center;
      margin-top: 0.938rem;
      margin-bottom: 2.5rem;

      > div {
        text-align: left;
      }

      h6 {
        margin-bottom: 0.625rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 2.15em;
        color: var(--description-text-color);
      }
    }

    .design-icon,
    .creative-icon,
    .transform-icon {
      margin-bottom: 1rem;
    }
  }

  .about-us-image {
    width: 100%;
    margin-bottom: 3rem;
    height: auto;
  }

  .ellipsis-circle {
    position: absolute;
    bottom: 0rem;
    left: 25%;
  }

  .line-shape-custom {
    position: absolute;
    right: 6%;
    top: 6%;
  }

  .desc-list-content {
    > div:first-child {
      max-width: 95%;
      margin: 0 auto;
    }
  }

  .triangle {
    // animation-name: triangleAnimate;
    // animation-duration: 5s;
    // animation-iteration-count: infinite;
    height: 60px;
  }

  .ellipsis-circle {
    animation-name: ellipsisRotate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .line-shape-custom {
    stroke-dasharray: 1200;
    stroke-dashoffset: 0;
    animation: zigZagLineAnimate 5s ease-out;
    animation-iteration-count: infinite;
    width: 60px;
  }

  .yellow-ellipsis {
    // animation-name: ellipsisMove;
    // animation-duration: 3s;
    // animation-iteration-count: infinite;
    width: 60px;
    position: absolute;
    right: 4rem;
    bottom: -5rem;
  }

  .ellipsis-circle-green,
  .ellipsis-circle-green-1,
  .ellipsis-circle-green-2 {
    position: absolute;
    display: none;
  }

  .ellipsis-circle {
    width: 35px;
    position: absolute;
    bottom: -4rem;
  }

  .dots-group {
    display: none;
  }

  @keyframes triangleAnimate {
    0% {
      margin-left: 5%;
      transform: rotate(0);
    }
    50% {
      margin-left: 35%;
      transform: rotate(-120deg);
    }
    100% {
      margin-left: 5%;
      transform: rotate(0deg);
    }
  }

  @keyframes ellipsisRotate {
    0% {
      transform: rotate(0deg);
      margin-left: 0;
    }
    50% {
      transform: rotate(180deg);
      margin-left: -2rem;
    }
    100% {
      transform: rotate(360deg);
      margin-left: 0;
    }
  }

  @keyframes zigZagLineAnimate {
    0% {
      stroke-dashoffset: 1200;
    }

    50% {
      stroke-dashoffset: 600;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes ellipsisMove {
    0% {
      right: 4rem;
      bottom: -5rem;
    }
    40% {
      right: 2rem;
      bottom: -6rem;
    }
    80% {
      right: 6rem;
      bottom: -6rem;
    }
    100% {
      right: 4rem;
      bottom: -5rem;
    }
  }

  @media screen and (min-width: 48rem) {
    margin-bottom: 3rem !important;

    .about-us-desc {
      font-size: 1.125rem;
    }

    .sub-title .ideas::after {
      bottom: 6px;
    }

    .about-us-list {
      margin-top: 8rem;

      .list-content {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 1rem;
        }

        .design-icon,
        .creative-icon,
        .transform-icon {
          min-width: 62px;
          min-height: 62px;
          margin-left: 1rem;
          margin-right: 1rem;
          margin-bottom: 0;
        }
      }
    }

    .about-us-image {
      width: 92%;
      margin-bottom: 0;
    }

    .triangle {
      height: 120px;
    }

    .line-shape-custom {
      width: 110px;
    }

    .ellipsis-circle-green,
    .ellipsis-circle-green-1 {
      display: block;
    }

    .ellipsis-circle {
      width: 64px;
      bottom: -6rem;
    }

    .ellipsis-circle-green {
      left: 3rem;
      bottom: 17rem;
    }

    .ellipsis-circle-green-1 {
      right: 18%;
      top: 10%;
    }

    .yellow-ellipsis {
      width: 90px;
      right: 14rem;
      bottom: -6rem;
    }

    @keyframes triangleAnimate {
      0% {
        margin-left: 5%;
        transform: rotate(0);
      }
      50% {
        margin-left: 50%;
        transform: rotate(-120deg);
      }
      100% {
        margin-left: 5%;
        transform: rotate(0deg);
      }
    }

    @keyframes ellipsisRotate {
      0% {
        transform: rotate(0deg);
        margin-left: 0;
      }
      50% {
        transform: rotate(180deg);
        margin-left: -2rem;
      }
      100% {
        transform: rotate(360deg);
        margin-left: 0;
      }
    }

    @keyframes zigZagLineAnimate {
      0% {
        stroke-dashoffset: 1200;
      }

      50% {
        stroke-dashoffset: 600;
      }

      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes ellipsisMove {
      0% {
        right: 14rem;
        bottom: -6rem;
      }
      40% {
        right: 12rem;
        bottom: -7rem;
      }
      80% {
        right: 16rem;
        bottom: -7rem;
      }
      100% {
        right: 14rem;
        bottom: -6rem;
      }
    }
  }

  @media screen and (min-width: 62rem) {
    .about-us-image {
      width: 88%;
      height: 75%;
    }

    .desc-list-content {
      > div:first-child {
        max-width: 95%;
        margin: 0 auto;
      }
    }

    .yellow-ellipsis {
      width: 140px;
      position: absolute;
      bottom: 0;
      right: 15rem;
      z-index: 0;
    }

    .about-us-list {
      margin-top: 3.75rem;

      .list-content {
        align-items: start;
        justify-content: start;
        flex-direction: column;

        .design-icon,
        .creative-icon,
        .transform-icon {
          margin-left: 0;
          margin-bottom: 1rem;
        }

        .design-icon,
        .creative-icon {
          margin-left: -1rem;
        }
      }
    }

    .ellipsis-circle-green-2 {
      right: 40%;
      top: 2%;
      display: block;
    }

    .triangle {
      left: 5%;
      position: relative;
    }

    .dots-group {
      display: block;
      position: absolute;
      right: 0;
      bottom: -1rem;
    }

    .ellipsis-circle {
      bottom: -2rem;
    }

    @keyframes ellipsisMove {
      0% {
        right: 11rem;
      }
      50% {
        right: 9rem;
        bottom: 0.5rem;
      }
      80% {
        right: 13rem;
        bottom: 0rem;
      }
      100% {
        right: 11rem;
        bottom: 1.5rem;
      }
    }
  }

  @keyframes zigZagLineAnimate {
    0% {
      stroke-dashoffset: 1200;
    }

    50% {
      stroke-dashoffset: 600;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}

// About Us Component Styles End
// Mission Vision Component Styles Start

.mission-vision-component {
  margin-bottom: 1rem !important;

  .mission-content-container,
  .vision-content-container {
    padding: 0 1rem;
  }

  .mission-sub-title {
    font-size: 1.25rem;
    color: var(--mission-blue-variant);
  }

  .mission-title,
  .vision-title {
    margin-bottom: 2rem;
  }

  .mission-desc,
  .description-container > p {
    font-size: 0.875rem;
    line-height: 1.9em;
    color: var(--description-text-color);
  }

  .vision-title {
    margin-top: 2rem;
  }

  .vision-icon,
  .vision-bg {
    display: none;
  }

  .mission-vision-image {
    padding: 0 2rem;
    display: none;
  }

  .cloud-group-image {
    position: absolute;
    top: -5rem;
    left: 5%;
    opacity: 0.5;
    animation-name: cloudsMoveAnimation;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    z-index: -1;
    display: none;
  }

  .mission-image {
    z-index: 1;
    max-width: 80%;
    margin: auto;
    display: block;
    margin-bottom: 1rem;
    height: auto;
  }

  .mountain-image {
    position: absolute;
    right: -3rem;
    z-index: 0;
    top: -2rem;
    display: none;
  }

  .vision-content-container {
    .card {
      border: 0;
      background: var(--app-white-shade);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 0.375rem;
      padding-top: 0.938rem;

      .card-text {
        color: var(--description-text-color);
        font-size: 0.875rem;
      }
    }

    .vision-card-last {
      margin-top: 1.5rem;
    }

    .description-container {
      display: flex;
      align-items: center;
      height: 100%;

      .arrow {
        &::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-left: 7.5px solid transparent;
          border-right: 7.5px solid transparent;
          border-bottom: 10px solid var(--card-triangle-blue);
        }
      }

      .card-1 {
        .arrow {
          &::before {
            bottom: 2.5rem;
            transform: rotate(45deg);
            left: 6rem;
          }
        }
      }

      .card-2 {
        margin-top: 1.5rem;
        margin-bottom: 2rem;

        .arrow {
          &::before {
            bottom: 2rem;
            transform: rotate(310deg);
            right: 4rem;
            border-bottom-color: var(--color-yellow-tertiary);
          }
        }
      }

      .card-3 {
        .card-text {
          &::after {
            content: "";
            position: relative;
            width: 120px;
            height: 7px;
            border-radius: 40px;
            background: var(--light-green-color);
            margin: 0.75rem 0;
            display: block;
            top: 1rem;
          }

          &::before {
            bottom: 2rem;
            transform: rotate(45deg);
            left: 10rem;
            border-bottom-color: var(--color-yellow-tertiary);
          }
        }
      }
    }

    .vision-bg {
      position: absolute;
      right: -8%;
    }
  }

  .dots-group {
    position: absolute;
    left: -30px;
    bottom: 0;
    width: auto;
  }

  @keyframes cloudsMoveAnimation {
    0% {
      left: 5%;
    }
    100% {
      left: 100%;
    }
  }

  @media screen and (min-width: 48rem) {
    margin-bottom: 2.5rem !important;

    .mission-sub-title {
      font-size: 1.563rem;
    }

    .mission-vision-image {
      width: 65%;
      margin: auto;
      padding: 0;
    }

    .mission-desc {
      position: absolute;
      margin-top: 1rem;
      margin-left: 1rem;
      max-width: 400px;
    }

    .mission-desc,
    .description-container > p {
      font-size: 1rem;
    }

    .mission-image {
      float: right;
      max-width: 50%;
    }
  }

  @media screen and (min-width: 62rem) {
    .mission-content-container {
      position: relative;
    }

    .mission-desc {
      font-size: 1.125rem;
      position: absolute;
      margin-top: 8rem;
      margin-left: 3rem;
      max-width: 655px;
    }

    .mission-image {
      float: right;
      right: 12%;
      position: relative;
      display: block;
      max-width: 100%;
    }

    .mountain-image,
    .cloud-group-image {
      display: block;
    }

    .mission-content-container,
    .vision-content-container {
      padding: 0 2rem 0 0;
    }

    .vision-title {
      margin-top: 4rem;
    }

    .vision-content-container {
      .card {
        margin-left: 1rem;

        .card-text {
          font-size: 1.125rem;
        }
      }
    }
  }

  @media screen and (min-width: 75rem) {
    .vision-section {
      margin-top: 0;
      position: relative;

      .vision-content-container {
        top: 0;
        position: relative;
        float: right;
      }
    }

    .vision-icon {
      margin-top: -3rem;
      margin-left: -3rem;
      display: block;
      animation: visionImageMove 5s ease-out;
      animation-iteration-count: infinite;
    }

    .mission-title {
      margin-bottom: 2rem;
    }

    .vision-content-container {
      .card {
        margin-left: 0;
      }
    }
  }

  @keyframes visionImageMove {
    0% {
      left: 3rem;
    }
    50% {
      left: 5rem;
    }
    100% {
      left: 3rem;
    }
  }
}

// Mission Vision Component Styles End
// Services Component Styles Start

.services-component-container {
  position: relative;
  .title-container {
    margin-bottom: 2rem;
  }

  .services-list-content {
    max-width: 428px;
    margin: 0 auto;

    .card {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;
      padding: 1.563rem;
      border: 0;
      border-radius: 0.625rem;
      background: var(--app-white-shade);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
      text-align: center;
      min-height: 98%;

      .card-title {
        margin-bottom: 1.25rem;
      }

      .card-text {
        margin-top: 1.5rem;
        font-size: 0.875rem;
        line-height: 1.85em;
        color: var(--description-text-color);
      }
    }
  }

  .circle-icon-services,
  .services-arrow {
    display: none;
  }

  @media screen and (min-width: 48rem) {
    .services-list-content {
      clear: right;
      max-width: 1364px !important;

      .card {
        min-height: 98%;
        margin: 0rem 0.75rem;
        padding: 0.54rem;

        .card-body {
          > svg {
            margin-top: 1rem;
          }
        }

        .card-text {
          margin-top: 2.875rem;
        }

        .digital-icon ~ .card-text {
          margin-top: 1rem;
        }
      }
    }

    .circle-icon-services {
      margin: -2rem 0 2rem 0;
      float: right;
      margin-right: 30%;
      display: block;

      path {
        animation-name: colorChange;
        animation-duration: 5s;
        animation-iteration-count: infinite;
      }
    }

    .services-arrow {
      position: absolute;
      top: 5rem;
      left: 35%;
      display: block;
    }
  }

  @media screen and (min-width: 62rem) {
    .services-list-content {
      .card {
        min-height: 100%;
      }
    }
  }

  @keyframes colorChange {
    0% {
      fill: var(--card-variation-orange);
    }
    20% {
      fill: var(--color-violet-tertiary);
    }
    40% {
      fill: var(--banner-text-underline-shade);
    }
    60% {
      fill: var(--sky-blue-variant);
    }
    80% {
      fill: var(--color-blue-theme);
    }
    100% {
      fill: var(--color-blue-theme);
    }
  }

  @media screen and (min-width: 62rem) {
    .services-list-content {
      .card {
        min-height: 95%;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
        padding: 1.563rem;

        .card-text {
          margin-top: 3.5rem;
          font-size: 1rem;
        }

        .card-body {
          > svg {
            margin-top: 0;
          }
        }

        .digital-icon ~ .card-text {
          margin-top: 1.5rem;
        }
      }
    }

    .title-container {
      margin-top: 3.5rem;
      margin-bottom: 3rem;
    }
  }
}

//Services Component Style Ends
//Contact Us Component Style Starts

.contactus-component-container {
  padding-top: 0.6rem;

  p {
    color: var(--description-text-color);
    font-size: 1rem;
    padding: 0 20px;
    line-height: 1.5em;
    font-weight: 500;
    margin: 2rem auto 3.875rem auto;
    max-width: 675px;
    opacity: 0.5;
    transform: translate(0, 30px);
    transition: 1s ease opacity, 1.5s ease transform;

    &.start-animate-ud {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  form {
    max-width: 565px;
    margin: 0 auto;
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin-bottom: 2rem;

    > div > * {
      margin-bottom: 1.25rem;
    }

    textarea {
      resize: none;
    }

    .upload-doc-label {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    #attachments {
      min-height: auto;
      background: transparent;
      padding-left: 0;
      color: var(--app-black-shade);
      font-size: 0.875rem;

      &::-webkit-file-upload-button {
        min-width: 140px;
        min-height: 44px;
        background-color: var(--app-black-shade);
        text-align: center;
        color: var(--app-white-shade);
        border-right: 0;
        border-radius: 6px;
        margin: 0;
        margin-right: 0.625rem;
      }
    }

    .PhoneInput {
      .PhoneInputCountry {
        padding: 0.625rem 0.875rem;
        margin-right: 0.5rem;
        background: var(--input-grey-variant);
        border-radius: 7px;
      }
    }
  }

  > div:last-child {
    p {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }

    .send-button {
      position: relative;
      text-align: center;
      margin: auto;
      display: block;
      right: 0;
      margin-top: 2.5rem;
      bottom: 1rem;
      min-width: 140px;
      min-height: 50px;
      background: var(--blue-shade-variation-dark);
      border-radius: 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--banner-typo-color-one);

      &:hover {
        box-shadow: none;
      }
    }
  }

  @media screen and (min-width: 48rem) {
    p {
      font-size: 1.125rem;
    }

    form {
      padding: 4rem;
      margin-bottom: 5rem;
    }

    .send-button {
      position: absolute;
      right: 2rem;
      bottom: 1.5rem;
      min-width: 140px;
      min-height: 50px;
      background: var(--blue-shade-variation-dark);
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 500;
      color: var(--banner-typo-color-one);
    }

    > div:last-child {
      p {
        margin: 2rem auto 3.875rem auto;
      }
    }
  }
}

//Contact Us Component Style Ends
//Success Stories Component Style Starts

.success-stories-component-container {
  margin-top: 0.75rem !important;

  .title-container {
    margin-top: 1.5rem;
  }

  .vee-content {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .rect-group {
      left: -88px;
      position: relative;
      top: 2rem;
    }

    > div:first-child {
      background-image: url("../../assets/images/vee-girl.svg");
      background-repeat: no-repeat;
      background-size: auto;
      min-width: 50%;
    }

    .vee-image {
      padding-top: 2rem;
      padding-bottom: 1rem;
    }

    > div:last-child {
      min-width: 330px;
      margin-left: -2rem;
      text-align: center;
      padding: 2.5rem;
      border-bottom-right-radius: 100px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
      background: var(--blue-shade-variation-dark);
      color: var(--app-white-shade);
      z-index: -1;
      position: relative;
      margin-top: 0;

      p {
        font-size: 1.25rem;
        span {
          font-size: 1.875rem;
          margin-top: 0.8rem;
        }
      }
    }

    .dots-image {
      position: absolute;
      top: -85%;
      display: none;
    }

    > div:first-child {
      display: none !important;
    }
  }

  @media screen and (min-width: 48rem) {
    margin-top: 0 !important;

    .title-container {
      margin-top: 2.25rem;
    }

    .dots-image {
      display: block;
    }

    > div:last-child {
      margin-top: 5rem;
    }

    > div:first-child {
      display: flex !important;
    }

    .vee-content {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  @media screen and (min-width: 62rem) {
    margin-top: 0 !important;

    > div:first-child {
      display: block !important;
    }

    .vee-content > div:first-child {
      display: flex !important;
    }
  }
}

//Success Stories Component Style Ends
//Technology component style starts

.technology-component-container {
  .title-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .technology-content-container {
    max-width: 420px;
    margin: 0 auto;

    > div {
      padding: 0;
    }
  }

  .card {
    margin: 1.25rem;
    box-shadow: 0px 4px 50px rgba(140, 149, 255, 0.1);
    border: 1px solid rgba(140, 149, 255, 0.2);
    border-radius: 20px;

    .card-body {
      text-align: center;
      padding-top: 3rem;
      padding-bottom: 1rem;
      min-height: 225px;

      .card-title {
        font-weight: 600;
        text-align: left;
      }

      > svg:first-child {
        width: 50px;
        position: absolute;
        top: -25px;
        height: 50px;
        margin-left: -25px;
      }

      .card-text {
        font-size: 0.875rem;
        text-align: left;
        line-height: 1.5em;
        color: var(--black-share-variation-1);
      }

      .dots {
        &::before,
        &::after {
          position: absolute;
          top: 2.15rem;
          left: 60%;
        }

        &.animate::before {
          animation-name: animateDotsTech;
          animation-duration: 0.5s;
        }

        &.animate::after {
          animation-name: animateDotsTechSec;
          animation-duration: 0.8s;
        }

        &::after {
          left: 68%;
        }
      }
    }
  }

  .db-content {
    .dots::before {
      background-color: rgba(var(--color-yellow-secondary-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-yellow-secondary-rgb), 0.4);
    }

    .tools-image {
      background-image: url("../../assets/images/database-tools.svg");
      background-size: contain;
    }
  }

  .dev-content {
    .dots::before {
      background-color: rgba(var(--color-purple-primary-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-purple-primary-rgb), 0.4);
    }

    .tools-image {
      background-image: url("../../assets/images/dev-tools.svg");
    }
  }

  .web-tool-content {
    .dots::before {
      background-color: rgba(var(--color-violet-secondary-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-violet-secondary-rgb), 0.4);
    }

    .card-body {
    }

    .tools-image {
      background-image: url("../../assets/images/web-tools.svg");
      background-size: 90%;
    }
  }

  .orm-tool-content {
    .dots::before {
      background-color: rgba(var(--color-orange-variation-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-orange-variation-rgb), 0.4);
    }

    .card-body {
    }

    .tools-image {
      background-image: url("../../assets/images/orm-tools.svg");
    }
  }

  .cloud-image-content {
    .dots::before {
      background-color: rgba(var(--color-blue-variation-secondary-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-blue-variation-secondary-rgb), 0.4);
    }

    .tools-image {
      background-image: url("../../assets/images/cloud-tools.svg");
    }
  }

  .cloud-image-content {
    .dots::before {
      background-color: rgba(var(--color-blue-variation-secondary-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-blue-variation-secondary-rgb), 0.4);
    }

    .cloud-arrow {
      position: absolute;
      right: 20px;
      bottom: 12px;
    }
  }

  .odoo-image-content {
    .dots::before {
      background-color: rgba(var(--color-yellow-secondary-rgb), 0.5);
    }

    .dots::after {
      background-color: rgba(var(--color-yellow-secondary-rgb), 0.4);
    }

    .card-text {
      margin-top: 1rem;
    }

    .group-dots-tech {
      position: absolute;
      right: -20px;
    }

    .tools-image {
      background-image: url("../../assets/images/solution-framework.svg");
    }
  }

  .tools-image {
    width: 92%;
    height: 40px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 15px;
    background-size: contain;
  }

  .custom-shape-dots {
    position: absolute;
    left: 0;
    top: 3rem;
    height: 85px;
  }

  @keyframes animateDotsTech {
    from {
      left: 58%;
    }
    to {
      left: 60%;
    }
  }

  @keyframes animateDotsTechSec {
    from {
      left: 60%;
    }
    to {
      left: 68%;
    }
  }

  @media screen and (min-width: 48rem) {
    max-width: unset;

    .title-container {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .technology-content-container {
      max-width: unset;
      padding: 0 25px;

      .card-body {
        min-height: 262px;

        .card-title {
          margin-bottom: 0.5rem;
        }
      }

      .odoo-image-content {
        .group-dots-tech {
          bottom: -25px;
        }
      }

      .cone-icon-db,
      .circle-icon-dev {
        position: absolute;
        bottom: 25px;
      }
    }

    .title-container {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .card {
      margin-bottom: 3.5rem;

      .card-body {
        padding-top: 4rem;

        & > svg:first-child {
          top: -48.5px;
          width: auto;
          height: auto;
          margin-left: -48.5px;
        }

        .card-text {
          font-size: 1.125rem;
        }
      }
    }
  }

  @media screen and (min-width: 62rem) {
    .custom-shape-dots {
      height: 126px;
    }
  }
}

//Technology component style ends
//Solution component style start

.solutions-component-container {
  .title-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .solution-desc {
    max-width: 700px;
    margin: 1rem auto;
    font-size: 0.875rem;
    color: var(--description-text-color);
    line-height: 1.875em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .solutions-bg,
  .solutions-footer,
  .half-circle-solutions,
  .dots-group,
  .circle-group {
    display: none;
  }

  @media screen and (min-width: 48rem) {
    margin-bottom: 4rem;

    .title-container {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .solution-desc {
      font-size: 1rem;
      margin: 3rem auto;
      padding-left: 0;
      padding-right: 0;
    }

    .solutions-content-container {
      margin-left: 0.938rem;
      margin-right: 0.938rem;
    }

    .solutions-bg {
      display: block;
      position: absolute;
      height: 350px;
      margin-left: -2rem;
      margin-top: -10rem;
      animation-name: solutionsBgAnimate;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    .solutions-footer {
      position: absolute;
      right: 0;
      margin-top: -3rem;
      display: block;
    }

    .half-circle-solutions {
      display: block;
      margin-left: 12%;
      margin-top: 0%;
      position: absolute;
    }

    .circle-group {
      position: absolute;
      display: block;
      right: 0;
      margin-top: 6.5rem;
      margin-right: 0.5rem;
      animation-name: circleAnimate;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }

  @media screen and (min-width: 62rem) {
    .title-container {
      margin-top: 5rem;
      margin-bottom: 4rem;
    }

    & + .line-separator {
      margin: 4rem 0;
    }

    .solutions-bg {
      display: block;
      position: absolute;
      margin-top: -10rem;
      height: 478px;
      margin-left: 0;
    }

    .dots-group {
      position: absolute;
      right: -1rem;
      display: block;
    }

    .half-circle-solutions {
      margin-left: 3%;
    }
  }

  @keyframes circleAnimate {
    0% {
      margin-right: 0.5rem;
    }
    50% {
      margin-right: 7rem;
    }
    100% {
      margin-right: 0.5rem;
    }
  }

  @keyframes solutionsBgAnimate {
    0% {
      margin-top: -10rem;
    }
    50% {
      margin-top: -12rem;
    }
    100% {
      margin-top: -10rem;
    }
  }
}

//Solution component style ends
//Web App component style starts

#web-app-component-container {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;

  h2 {
    margin-bottom: 1rem;

    > span {
      z-index: 0;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 22%;
        left: -2px;
        height: 8px;
        background-color: var(--light-green-variation);
        bottom: 6px;
        z-index: -1;
      }
    }
  }

  > p {
    margin: 1.5rem;
    font-size: 0.875rem;
    color: var(--description-text-color);
    line-height: 1.875em;

    &:nth-of-type(2) + .line-separator {
      position: absolute;
      left: 4%;
      margin-top: 20%;
    }
  }

  > img {
    margin-top: 1rem;
    max-width: 70%;

    & ~ p {
      margin-top: 0;
    }
  }

  .add-icon {
    display: none;
    position: absolute;
    left: 7%;
    margin-top: 2%;
    animation-name: symbolAnimate;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .curve-thread-shape {
    display: none;
    position: absolute;
    right: 22%;
    margin-top: -5%;
    animation-name: curveMove;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }

  .web-app-arrow {
    transform: scale(-1, 1);
    margin-left: 20%;
    margin-top: 3%;
    position: absolute;
  }

  .line-curve-right {
    position: absolute;
    right: -22px;
    margin-top: -12rem;
    display: none;
  }

  .circle-group {
    position: absolute;
    top: -5rem;
    right: -5rem;
  }

  p:nth-of-type(2) + .line-separator {
    display: none;
  }

  .desc {
    p {
      color: var(--description-text-color);
      font-size: 0.875rem;
      padding: 0 1rem;
    }
  }

  .circle-group {
    display: none;
  }

  .web-bg {
    max-width: 80%;
    margin: 1rem 0;
  }

  @keyframes symbolAnimate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes curveMove {
    0% {
      margin-left: 15%;
      transform: rotate(0deg);
    }
    50% {
      margin-left: 20%;
      transform: rotate(-45deg);
    }
    100% {
      margin-left: 15%;
      transform: rotate(0deg);
    }
  }

  @media screen and (min-width: 48rem) {
    margin-top: 5rem;
    margin-bottom: 0;
    text-align: center;

    h2 {
      margin-bottom: 2rem;
    }

    > p {
      margin: 0rem auto;
      padding: 1rem 1.25rem;
      max-width: 705px;
      font-size: 1rem;
    }

    p:nth-of-type(2) + .line-separator {
      position: absolute;
      left: 2%;
      margin-top: -8%;
      display: block;
    }

    .add-icon {
      display: block;
    }
  }

  @media screen and (min-width: 62rem) {
    .curve-thread-shape,
    .line-curve-right {
      display: block;
    }

    .title-container {
      margin-bottom: 4rem;
    }

    .web-bg {
      margin-left: -3rem;
    }

    .desc {
      font-size: 1.125rem;
      line-height: 1.875em;
      padding-left: 4%;
      padding-right: 4%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 1.125rem;
      }
    }
  }
}

//Web App component style ends
//Mobile app component style starts

#mobile-app-component-container {
  margin-top: 1.5rem;

  > .title-container {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .android-app-container {
    margin: 0 1rem;

    .android-content-container {
      margin-top: 0rem;

      .title-container {
        margin-bottom: 1rem;
      }

      .card {
        border: 0;
        box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 1.5rem;
        width: auto;

        .card-text {
          font-size: 0.875rem;
          line-height: 1.5em;
          color: rgba(var(--app-black-shade-rgb), 0.54);
        }

        .card-body {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 85px;
            height: 7px;
            background: var(--sky-blue-variant);
            border-radius: 10px;
            bottom: -10px;
            right: 1rem;
          }

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 7.5px solid transparent;
            border-right: 7.5px solid transparent;
            border-bottom: 10px solid var(--color-pink-variation);
            transform: rotate(45deg);
            right: 0;
            bottom: 0rem;
          }
        }
      }
    }

    .cone-android {
      position: absolute;
      width: 36px;
    }
  }

  .android-bg {
    display: none;
    width: 40%;
  }

  .icon-group-1,
  .rect-group,
  .app-section-separator {
    display: none;
  }

  .android-section-title,
  .ios-section-title,
  .hybrid-section-title {
    .title-content {
      position: relative;
    }
  }

  @media screen and (min-width: 48rem) {
    margin-top: 0;

    > .title-container {
      margin-top: 4rem;
      margin-bottom: 1rem;
    }

    .android-app-container {
      margin: 3rem 1.5rem 2rem 1.5rem;

      .android-content-container {
        margin-top: 0;
        min-width: 57%;
        top: 0;
        position: relative;

        .title-container {
          margin-bottom: 2rem;
        }

        .card {
          font-size: 1rem;
          padding: 2.5rem;

          .card-text {
            font-size: 1rem;
          }

          .card-body {
            &::before {
              right: 4rem;
            }

            &::after {
              right: 2rem;
            }
          }
        }
      }

      .android-bg {
        width: 43%;
        top: -3rem;
        position: relative;
        z-index: -1;
      }

      .cone-android {
        width: 72px;
      }
    }
  }

  @media screen and (min-width: 62rem) {
    > div {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

    .android-bg {
      display: block;
    }

    .android-app-container {
      margin: 4rem 1.5rem;
      margin-bottom: 7rem;

      .android-content-container {
        margin-top: 0;
        top: 2rem;
        min-width: 57%;
        position: relative;

        .title-container {
          margin-bottom: 2rem;
        }

        .card {
          width: 108%;
          .card-text {
            font-size: 1.125rem;
          }
        }
      }

      .android-bg {
        width: 40%;
        top: 1rem;
        position: relative;
        z-index: 0;
      }
    }

    .icon-group-1 {
      display: block;
      position: absolute;
      top: -3rem;
      right: 8rem;
    }

    .rect-group {
      display: block;
      position: absolute;
      right: 0;
    }

    .app-section-separator {
      display: block;

      .curved-line-group-mobile {
        margin-top: 3rem;
        margin-left: 42%;
        top: 3.5rem;
        position: relative;
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
        animation: curvedLineMove 5s;
        animation-iteration-count: infinite;
      }
    }

    @keyframes curvedLineMove {
      to {
        stroke-dashoffset: 0;
      }
    }
  }

  .ios-app-container {
    .ios-bg {
      width: 55%;
      display: none;
    }

    .ios-content-container {
      margin-top: 0;
      width: 100%;
      position: relative;

      .title-container {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      .card {
        border: 0;
        box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 1.5rem;
        position: relative;
        right: 0;
        margin: 0 1.5rem;

        .card-text {
          font-size: 0.875rem;
          line-height: 1.5em;
          color: rgba(var(--app-black-shade-rgb), 0.54);
        }

        .card-body {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 85px;
            height: 7px;
            background: var(--sky-blue-variant);
            border-radius: 10px;
            bottom: -10px;
            right: 2rem;
          }

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 7.5px solid transparent;
            border-right: 7.5px solid transparent;
            border-bottom: 10px solid var(--color-pink-variation);
            transform: rotate(45deg);
            right: 0;
            bottom: 0rem;
          }
        }
      }
    }

    .contact-icon {
      position: absolute;
      left: 31.5rem;
      margin-top: 0rem;
      display: none;
    }

    @media screen and (min-width: 48rem) {
      .ios-content-container {
        .card {
          .card-text {
            font-size: 1rem;
          }

          .card-body {
            &::before {
              right: 4rem;
            }

            &::after {
              right: 2rem;
            }
          }
        }
      }
    }

    @media screen and (min-width: 62rem) {
      margin-bottom: 5rem;

      .ios-bg {
        display: block;
      }

      .ios-content-container {
        margin-top: 7rem;
        width: 100%;

        .title-container {
          margin-bottom: 3rem;
          margin-top: 2rem;
        }

        .card {
          margin-left: 5%;
          margin-right: 5%;
          margin-bottom: 5rem;

          .card-text {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  .hybrid-app-container {
    .hybrid-bg {
      width: 40%;
      display: none;
    }

    .hybrid-content-container {
      width: 100%;
      position: relative;

      .title-container {
        top: 0rem;
        position: relative;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
      }

      .card {
        border: 0;
        box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 1.5rem;
        position: relative;
        top: 0rem;
        margin: 0 1.5rem;
        width: auto;
        margin-bottom: 1.5rem;

        .card-text {
          color: var(--description-text-color);
          font-size: 0.875rem;
          line-height: 1.875em;
        }
      }
    }

    .dots-group {
      position: absolute;
      right: 1rem;
      width: 65px;
    }

    .hybrid-grouped-shape {
      display: none;
    }

    @media screen and (min-width: 48rem) {
      .hybrid-content-container {
        margin-top: 2rem;

        .card {
          margin-bottom: 0;

          .card-text {
            font-size: 1rem;
          }
        }

        .title-container {
          margin-bottom: 2rem;
          margin-top: 2rem;
        }
      }

      .dots-group {
        width: 98px;
        right: 1rem;
      }

      .hybrid-grouped-shape {
        display: block;
      }
    }

    @media screen and (min-width: 62rem) {
      .hybrid-bg {
        display: block;
        margin-left: -2.5rem;
      }

      .hybrid-content-container {
        width: 60%;
        margin-top: 0;

        .title-container {
          top: 3rem;
          margin-bottom: 0rem;
          margin-top: 7rem;
        }

        .card {
          left: -8.5rem;
          top: 6rem;
          margin-right: 2rem;
          width: 115%;

          .card-text {
            font-size: 1.125rem;
          }
        }

        .dots-group {
          position: absolute;
          right: 6rem;
          margin-top: 10px;
        }

        .hybrid-grouped-shape {
          z-index: 1;
          position: relative;
          margin-left: -7rem;
          margin-top: 3.2rem;
        }
      }
    }
  }
}

//Mobile app component style ends
//Why Choose Us component style starts
#why-choose-us {
  margin-bottom: 2rem;

  .why-choose-us-content {
    padding: 1.25rem;

    .choose-us-image {
      width: 100%;
      left: -12px;
      margin-right: 0;
      right: 0;
      height: auto;
      position: relative;
    }

    .title-container {
      margin-top: 4rem;
    }

    > span {
      top: 3rem;
      position: relative;
      margin-left: 45%;
    }

    span > p {
      font-size: 0.875rem;
      line-height: 2.225em;
      margin: 1.2rem 0;
      display: block;
      color: var(--description-text-color);
    }

    .choose-us-group {
      position: absolute;
      left: -250px;
      margin-top: 26%;
    }

    .grouped-circle-image {
      position: absolute;
      margin-top: 22%;
      margin-left: -9%;
    }
  }

  .cloud-arrow {
    position: absolute;
    transform: scale(-1, 1);
    left: 75%;
    margin-top: 1.563rem;
  }

  @media screen and (min-width: 48rem) {
    .why-choose-us-content {
      .choose-us-image {
        float: left;
        width: 50%;
        margin-right: 25px;
        left: -10%;
        position: relative;
        height: auto;
      }

      .choose-us-group {
        margin-left: 0;
        position: absolute;
        margin-top: 26%;
      }
    }
  }

  @media screen and (min-width: 62rem) {
    .why-choose-us-content {
      span > p {
        font-size: 1.125rem;
      }

      // .choose-us-group {
      //   position: absolute;
      //   left: -18%;
      // }
    }
  }
}
//Why Choose us component style ends
//UI UX component style starts
#ui-ux-component {
  .title-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .desc {
    margin: 0 auto;
    padding: 0 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .content-ui,
  .content-ux {
    p {
      color: var(--description-text-color);
    }
  }

  .user-interface-section,
  .user-experience-section {
    margin-top: 1.5rem;
    > div {
      > h4 {
        padding: 0 1.25rem;
      }
      > .content-ui,
      > .content-ux {
        padding: 0 1.25rem;
        margin-top: 1rem;

        p {
          font-size: 0.875rem;
        }
      }
    }
  }

  .ui-bg,
  .ux-bg {
    margin: 0 auto;
    display: block;
    max-width: 95%;
  }

  @media screen and (min-width: 48rem) {
    .title-container {
      margin-top: 4rem;
      margin-bottom: 3rem;
    }

    .desc {
      max-width: 784px;
      text-align: center;
      font-size: 1.375rem;
      margin-bottom: 3rem;
    }

    .user-interface-section,
    .user-experience-section {
      margin-bottom: 3rem;

      h4,
      .content-ui,
      .content-ux {
        padding: 0 2.25rem;
      }

      .content-ux {
        max-width: 80%;
      }

      .content-ui,
      .content-ux {
        p {
          font-size: 1.125rem;

          &:first-child {
            margin-top: 1rem;
          }
        }
      }

      > div {
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
    }

    .user-interface-section,
    .user-experience-section {
      margin-top: 1.5rem;
      > div {
        > .content-ui,
        > .content-ux {
          p {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}
//UI UX component style ends
//Test services component style starts
#test-services-component {
  .service-desc {
    margin: 0 1.25rem;
    max-width: 75%;
    color: var(--description-text-color);
  }

  p {
    line-height: 1.875em;

    h5 {
      color: var(--title-text-color-primary);
    }

    .spacing {
      padding: 0.5rem 0;
      display: block;
    }

    .min-spacing {
      padding: 0.25rem 0;
      display: block;
    }
  }

  .test-service-bg {
    display: none;
  }

  > h4 {
    margin: 1rem auto;
  }

  .service-desc {
    margin: 0 auto;
  }

  .test-services-content {
    > p {
      padding: 0 1rem;
      margin-top: 1.5rem;
      font-size: 0.875rem;

      h5 {
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 48rem) {
    .title-container {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    .service-desc {
      max-width: 800px;
      margin: 1.5rem auto 3rem auto;
    }

    .test-service-bg {
      right: -12%;
      position: relative;
      margin-top: 8%;
    }

    .test-services-content {
      > p {
        padding: 0;
        font-size: 1.125rem;

        h5 {
          text-align: left;
        }
      }
    }

    p {
      left: 3%;
      position: relative;
      max-width: calc(97% - 20px);
    }
  }

  @media screen and (min-width: 75rem) {
    .test-service-bg {
      display: block;
    }
  }
}
//Test services component style ends
//Team members component style starts
#our-members-component {
  margin: 3rem auto;

  .title-container {
    margin-bottom: 2rem;
  }

  > p {
    padding: 1.25rem;
    max-width: 1240px;
    margin: 0 auto;
    font-size: 1.063rem;
    padding-top: 0;
    font-weight: 500;
  }

  .members-content {
    margin-top: 2rem;
    padding: 1.25rem;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;

    > div {
      margin-right: 2.5rem;
      width: calc(33.33% - 40px);

      &:nth-child(n + 4) {
        margin-top: 5rem;
      }

      .member-name {
        margin-top: 1.625rem;
        margin-bottom: 0.313rem;
        color: rgba(var(--app-black-shade-rgb), 0.54);
      }

      .designation {
        font-size: 1.188rem;
        color: rgba(var(--app-black-shade-rgb), 0.54);
        font-weight: 500;
      }

      .desc {
        font-weight: 500;
        color: var(--app-black-shade);
        text-align: justify;
      }
    }
  }
}
//Team members component style ends
//Generic Styles

.title-container {
  opacity: 0;
  transform: translate(0, 30px);
  transition: 0.5s ease opacity, 0.85s ease transform;

  &.animate-content {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.cookie-content-modal {
  .modal-header {
    padding: 0;
    border: 0;

    .modal-title {
      color: var(--title-blue-variant);
    }
  }
  .modal-body {
    margin-top: 0.625rem;
    padding: 0;

    h6 {
      color: var(--modal-title-color);
    }

    > p {
      font-size: 0.875rem;
      line-height: 2.15em;
      color: var(--modal-desc-color);
    }
  }

  .modal-footer {
    border: 0;
    margin-top: 3rem;

    .btn-secondary {
      border: 1px solid var(--app-black-shade);
      min-width: 140px;
      min-height: 44px;
      color: var(--app-black-shade);
      background-color: var(--app-white-shade);
      border-radius: 0 22px 22px 35px;
      margin-right: 1.75rem;
      opacity: 0.4;
      transition: 0.15s linear all;

      &:hover {
        opacity: 0.8;
      }
    }

    .btn-primary {
      min-width: 177px;
      min-height: 44px;
      background-color: var(--app-black-shade);
      color: var(--app-white-shade);
      border: 0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 0 22px 22px 35px;
    }
  }
}
