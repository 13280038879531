:root {
  --app-white-shade: #ffffff;
  --app-white-shade-rgb: 255, 255, 255;
  --app-black-shade: #000000;
  --app-black-shade-rgb: 0, 0, 0;
  --app-max-width: 1500px;
  --title-blue-variant: #4b34dd;
  --modal-desc-color: #263238;
  --modal-title-color: #010101;
  --backdrop-color: #051537;
  --backdrop-color-rgb: 5, 21, 55;
  --border-color-input: #bdbdbd;
  --model-red-color: #fd6e6e;
  --blue-variation-light: #26d2f9;
  --color-text-black: #181818;
  --check-text-black: #3e3232;
  --checkbox-border-color: #999999;
  --disabled-text-color: #999999;
  --color-light-green: #D0FCFF;

  .default-theme {
    --app-header-bg-hex: #ffffff;
    --app-header-bg-rgb: 119, 50, 206;
    --nav-link-color: #000000;
    --nav-link-color-rbg: 0, 0, 0;
    --font-family-labosoft: "Poppins", sans-serif;
    --header-button-color-bg: #ff5050;
    --header-button-color-bg-rgb: 255, 80, 80;
    --header-button-text-color: #ffffff;
    --header-button-text-color-rgb: 255, 255, 255;

    --banner-background-color: linear-gradient(
      139.2deg,
      #3a8cd5 -9.27%,
      #2d62fd 100.23%
    );
    --banner-background-color-rgb: 135, 77, 209;
    --banner-typo-color-one: #fcfbff;
    --banner-text-underline-shade: #fdbc0c;
    --banner-button-text-color: #0b0b0c;
    --text-primary-color: #04053f;
    --text-secondary-color: #302b2b;
    --text-secondary-color-rgb: 48, 43, 43;
    --sky-blue-variant: #46f4ff;
    --card-variation-yellow: #ffc909;
    --card-variation-yellow-rgb: 255, 201, 9;
    --card-variation-sky-blue: #34fff3;
    --card-variation-sky-blue-rgb: 52, 255, 243;
    --card-variation-violet: #6f6dce;
    --card-variation-violet-rgb: 111, 109, 206;
    --card-variation-orange: #ff6871;
    --card-triangle-blue: #69ffdb;

    --title-background-text-grey: #f0f1f5;
    --title-background-text-grey-rgb: 240, 241, 245;
    --title-text-color-primary: #1e206a;
    --title-text-color-secondary: #3f5275;
    --light-green-variation: #0cfda6;
    --description-text-color: #5e5e5e;
    --mission-red-variant: #ff5050;
    --bullet-color: #3170ef;
    --violet-rgb-variation: 136, 14, 72;
    --footer-background-rgb: 136, 14, 72;

    --color-yellow-secondary: #ffe8bf;
    --color-yellow-secondary-rgb: 255, 232, 191;
    --color-purple-primary: #c0bfff;
    --color-purple-primary-rgb: 192, 191, 255;
    --color-violet-secondary: #bfd1ff;
    --color-violet-secondary-rgb: 191, 209, 255;
    --color-orange-variation: #ffbfbf;
    --color-orange-variation-rgb: 255, 191, 191;
    --color-blue-variation-secondary: #bff3ff;
    --color-blue-variation-secondary-rgb: 191, 243, 255;
    --color-violet-tertiary: #4b34dd;
    --color-violet-tertiary-rgb: 75, 52, 221;
    --color-dark-blue-rgb: 4, 32, 53;
    --footer-text-primary: #130f0f;
    --dark-blue-secondary: #042035;
    --solution-text-primary: #252525;
    --color-grey-shade: #f1f1f1;
    --border-color: #757575;
    --color-pink: #ff6e91;
    --color-green: #45d987;
    --color-navy: #3144f3;
    --color-light-blue: #c7e2fc;
    --color-orange: #ff6e83;
    --color-pink-variation: #ea69ff;
    --color-blue-theme: #5e50ff;
    --color-yellow-tertiary: #fdbc0c;
    --mission-blue-variant: #3e85fb;
    --light-green-color: #b4ff9a;
    --black-share-variation-1: #263238;
    --blue-shade-variation-dark: #011f63;
    --input-grey-variant: #f8f8f8;
    --link-color-secondary: #4a7dff;
    --color-blue-variation-light: #26d2f9;
    --blue-selected-color: #2e67f7;
    --option-text-color: #726e77;
  }
}
