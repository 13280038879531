.title-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .title-content {
    display: inline-block;
    position: relative;

    .front-display {
      font-size: 1rem;
      font-weight: 600;
      color: var(--title-text-color-primary);
      line-height: 1.25em;
    }

    .title-line {
      &:first-child {
        margin-right: 0.313rem;
      }

      &:last-child {
        margin-left: 0.313rem;
      }
    }
  }

  .background-text {
    text-transform: uppercase;
    font-size: calc(4vw + 1rem);
    font-weight: bold;
    color: var(--title-background-text-grey);
    line-height: 1em;
    white-space: nowrap;
    display: none;
  }
}

@media screen and (min-width: 48rem) {
  .title-container {
    .title-content {
      position: absolute;

      .front-display {
        font-size: 1.25rem;
      }

      .title-line {
        &:first-child {
          margin-right: 0.75rem;
        }

        &:last-child {
          margin-left: 0.75rem;
        }
      }
    }

    .background-text {
      display: block;
    }
  }
}

@media screen and (min-width: 62rem) {
  .title-container {
    .title-content {
      .front-display {
        font-size: 1.563rem;
      }
    }
  }
}
