html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

body {
  margin: 0;
  font-family: var(--font-family-labosoft);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

#root {
  height: 100%;
}

.app-container.labosoft {
  font-family: var(--font-family-labosoft);

  .btn {
    border: none;
  }

  .btn-check:focus + .btn-outline-secondary,
  .btn-outline-secondary:focus {
    box-shadow: none;
  }
}

.home-container {
  & > *:not(.banner-component-container) {
    max-width: var(--app-max-width);
    margin: 0 auto;
  }

  > div:first-of-type {
    max-width: unset;
  }

  .line-separator {
    margin: 2rem 0;
    text-align: center;
  }

  .mission-vision-component + .line-separator {
    top: 0rem;
    position: relative;
  }

  input,
  textarea {
    background: var(--input-grey-variant);
    border: 0;
    border-radius: 7px;
    font-size: 0.875rem;
    color: rgba(var(--app-black-shade-rgb), 0.6);
    min-height: 60px;
    padding-left: 1.375rem;

    &::-webkit-input-placeholder {
      font-size: 0.875rem;
      color: rgba(var(--app-black-shade-rgb), 0.6);
    }

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background: var(--input-grey-variant);
      color: rgba(var(--app-black-shade-rgb), 0.6);
    }

    &:hover {
      background: var(--input-grey-variant);
      color: rgba(var(--app-black-shade-rgb), 0.6);
    }
  }

  textarea {
    min-height: 132px !important;
    padding-top: 1.25rem;
  }

  .CookieConsent {
    width: 100% !important;

    #rcc-confirm-button {
      transition: 0.15s linear opacity;

      &:hover {
        opacity: 0.7;
      }
    }

    a {
      margin-left: 0.625rem !important;
      color: var(--link-color-secondary) !important;
      text-decoration: underline !important;
      cursor: pointer;
    }

    @media screen and (min-width: 62rem) {
      width: 50% !important;
    }
  }
}

.modal-dialog {
  max-width: 55% !important;

  .modal-content {
    border-radius: 20px 0px 30px !important;
    padding: 2rem 2rem 1.5rem 2rem !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
    max-height: 92vh;
    overflow: auto;
  }

  @media screen and (max-width: 48rem) {
    max-width: 92% !important;
    margin: 0 auto !important;
  }
}

.modal-backdrop {
  background: var(--backdrop-color) !important;
  opacity: 0.8 !important;
}

.clear-right {
  clear: right;
}

.dots {
  &::before,
  &::after {
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 50%;
  }
}

input.form-check-input[type="radio"] {
  &:checked {
    background-image: url("./assets/images/radio-checked.svg") !important;
    background-size: 50%;
    background-color: #ffffff !important;
    border-color: var(--disabled-text-color) !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

input.form-check-input[type="checkbox"] {
  &:checked {
    background-image: url("./assets/images/checkbox-checked.png") !important;
    background-size: 100%;
    background-color: #ffffff !important;
    border-color: var(--disabled-text-color) !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.invalid-feedback {
  margin-top: -0.875rem !important;
  position: absolute;
  margin-left: 0.313rem;
}

* {
  outline: none;
}

@media screen and (min-width: 48rem) {
  .dots {
    &::before,
    &::after {
      width: 16px;
      height: 16px;
    }
  }
}
