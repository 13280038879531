.zig-zag-renderer {
  position: relative;

  > div {
    display: flex;
    align-items: center;

    &:nth-child(2n),
    &:nth-child(n + 1) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  h5 {
    font-weight: 600;
    line-height: 1.5em;
    color: var(--solution-text-primary);
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5em;
    color: rgba(var(--app-black-shade-rgb), 0.54);
  }

  .icon-container {
    position: relative;
    display: none;

    svg {
      min-width: 120px;

      &:last-child {
        position: absolute;
        left: 4.5rem;
        top: 3.8rem;
        width: 120px;
        height: 65px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: #ff6e91;
      border: 3px solid var(--app-black-shade);
      top: calc(50% - 20px);
      left: -23px;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 7px);
      left: -11px;
      display: inline-block;
      width: 60px;
      height: 1px;
      border-top: 3px dotted var(--border-color);
    }
  }

  > div {
    &:nth-child(2n) {
      > div {
        padding-right: 0.625rem;
      }
    }

    &:nth-child(5n + 1) {
      .icon-container::before {
        background-color: var(--color-pink);
      }
    }

    &:nth-child(5n + 2) {
      .icon-container::before {
        background-color: var(--color-green);
      }
    }

    &:nth-child(5n + 3) {
      .icon-container::before {
        background-color: var(--color-navy);
      }
    }

    &:nth-child(5n + 4) {
      .icon-container::before {
        background-color: var(--color-light-blue);
      }
    }

    &:nth-child(5n + 5) {
      .icon-container::before {
        background-color: var(--color-orange);
      }
    }
  }

  &::after {
    width: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    background-color: var(--color-grey-shade);
  }

  .arrow-image {
    transform: scale(-1, 1);
  }

  @media screen and (min-width: 48rem) {
    p {
      font-size: 0.94rem;
    }

    .icon-container {
      display: block;
    }

    > div {
      > div {
        margin-left: -1.375rem;
      }

      &:nth-child(2n) {
        > div {
          padding-right: 0.625rem;
        }
      }
    }

    &::after {
      content: "";
    }
  }

  @media screen and (min-width: 62rem) {
    &::after {
      left: calc(50% - 4px);
    }

    .icon-container {
      svg {
        &:last-child {
          left: 3rem;
          top: 4rem;
        }
      }

      &::before {
        top: calc(50% - 19px);
        left: unset;
        right: -30px;
      }

      &::after {
        top: 1px;
        left: unset;
        right: -11px;
      }
    }

    > div {
      margin-left: initial;

      &:nth-child(even) {
        > div {
          margin-left: -1.375rem;
        }

        .icon-container {
          &::before {
            right: unset;
            left: -30px;
            top: calc(50% - 17px);
          }

          &::after {
            right: unset;
            left: -11px;
            top: calc(50% - 7px);
          }

          .arrow-image {
            transform: scale(-1, 1);
          }

          svg:last-child {
            left: 4.3rem;
          }
        }
      }

      &:nth-child(odd) {
        .icon-container {
          &::before {
            left: unset;
            right: -30px;
            top: calc(50% - 19px);
          }

          &::after {
            left: unset;
            right: -11px;
            top: calc(50% - 7px);
          }

          .arrow-image {
            transform: unset;
          }
        }
      }
    }
  }
}
